<template>
    <dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="venueClaimRequests"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.created_at="{ item }">
                {{ $date(item.created_at).format('MMM DD, YYYY HH:mm') }}
            </template>
            <template v-slot:item.actions="{ item }">
                <div v-if="item.status === 0">
                    <v-btn color="green" @click="accept(item)">accept</v-btn>
                    <v-btn color="red" @click="reject(item)">reject</v-btn>
                </div>
            </template>
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import VenueClaimRequest from "@/models/VenueClaimRequest";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "Index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            venueClaimRequests: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Venue', value: 'venue.name'},
                {text: 'Email', value: 'email'},
                {text: 'Phone', value: 'phone'},
                {text: 'Status', value: 'statusString'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    async mounted() {
        await this.loadData()
    },
    methods: {
        async accept(venueClaimRequest) {
            await venueClaimRequest.accept()
            await this.loadData()
        },
        async reject(venueClaimRequest) {
            await venueClaimRequest.reject()
            await this.loadData()
        },
        async loadData() {
            this.loading = true
            this.venueClaimRequests = await VenueClaimRequest.with('venue').get()
            this.loading = false
        }
    }
}
</script>

<style scoped>

</style>
