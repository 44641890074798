import Model from './Model'

export default class VenueClaimRequest extends Model {
    // Set the resource route of the model
    resource() {
        return 'venue-claim-requests'
    }

    get statusString() {
        switch (this.status) {
            case 0:
                return 'Pending'
            case 1:
                return 'Accepted'
            case 2:
                return 'Rejected'
            default:
                return 'error'
        }
    }

    accept() {
        return this.request(
            this._reqConfig({
                method: 'GET',
                url: `/api/venue-claim-requests/${this.id}/accept`,
                data: this
            }, {forceMethod: true})
        ).then(response => {
            return this._applyInstance(response.data.data || response.data)
        })
    }

    reject() {
        return this.request(
            this._reqConfig({
                method: 'GET',
                url: `/api/venue-claim-requests/${this.id}/reject`,
                data: this
            }, {forceMethod: true})
        ).then(response => {
            return this._applyInstance(response.data.data || response.data)
        })
    }
}
